// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__icon--f9b3f {\n  width: 0.9em;\n  height: 1em;\n  vertical-align: text-top;\n}\n", "",{"version":3,"sources":["webpack://./src/components/currency/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,wBAAwB;AAC1B","sourcesContent":[".icon {\n  width: 0.9em;\n  height: 1em;\n  vertical-align: text-top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles__icon--f9b3f"
};
export default ___CSS_LOADER_EXPORT___;
