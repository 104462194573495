import { WebRequest, WebResponse } from 'src/types/widget-data';
import { convertToSnakeCase } from 'src/utils/convert-to-snake-case';
import { fetchWithRetry } from 'src/utils/fetch-with-retry';

const REQUEST_TIMEOUT = 2000;

export const getWidgetData = async (body: WebRequest): Promise<WebResponse> => {
  const controller = new AbortController();
  const { signal } = controller;

  setTimeout(() => {
    controller.abort();
  }, REQUEST_TIMEOUT);

  try {
    const data = await fetchWithRetry({
      url: '/web',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(convertToSnakeCase(body)),
        signal,
      },
    });
    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('POST /web failed', e);

    return {
      experiments: {
        experiments: [],
        entityId: '',
      },
      aid: '',
      offers: { installments: [] },
    };
  }
};
