import React from 'react';
import { ACTIONS } from 'src/constants/common';
import { INSTALLMENTS_COUNT_DEFAULT } from 'src/context/store/app-provider';
import { AllowedCurrency, CurrencyType } from 'src/types/currency';
import { Experiment } from 'src/types/experiment';
import { Lang, Locale } from 'src/types/lang-locale-type';
import { WebResponse } from 'src/types/widget-data';
import { WidgetOffers } from 'src/types/widget-offers';
import { checkCurrency, formatCurrency } from 'src/utils/check-currency';
import { checkInstallmentsCount } from 'src/utils/check-installments-count';
import { checkLang } from 'src/utils/check-lang';
import { checkLocale } from 'src/utils/check-locale';
import { checkPrice } from 'src/utils/check-price';
import { DataSetRecord } from 'src/utils/get-data-from-dataset';
import { getLangAndLocale } from 'src/utils/get-lang-and-locale';

export interface State {
  formattedCurrency?: AllowedCurrency;
  priceRaw?: number;
  lang: Lang;
  locale: Locale;
  currency: CurrencyType;
  price: number;
  installmentsCount: number;
  experiments: Experiment[];
  aid: string;
  offers: WidgetOffers;
  loading: boolean;
  isBig?: boolean;
  isDark?: boolean;
  titles?: string[];
  isBold?: boolean;
  isMobileRounded?: boolean;
  isShortSplitIt?: boolean;
  webview?: boolean;
}

export type Actions =
  | {
      type: typeof ACTIONS.UPDATE_STORE;
      payload: Partial<State> & { skipRawPriceUpdate?: boolean };
    }
  | {
      type: typeof ACTIONS.PREPARE_ALSHAYA_LIMIT;
    }
  | {
      type: typeof ACTIONS.UPDATE_FROM_DATASET;
      payload: DataSetRecord;
    }
  | {
      type: typeof ACTIONS.SET_TITLES;
      payload: string[];
    }
  | {
      type: typeof ACTIONS.PREPARE_PAY_IN_FULL_POPUP;
    }
  | {
      type: typeof ACTIONS.PREPARE_SCHEDULE_POPUP;
    }
  | {
      type: typeof ACTIONS.GET_WIDGET_DATA;
      payload: WebResponse;
    };

export const storeReducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case ACTIONS.GET_WIDGET_DATA: {
      return {
        ...state,
        experiments: action.payload.experiments?.experiments || [],
        aid: action.payload.aid || '',
        offers: action.payload.offers || { installments: [] },
        loading: false,
      };
    }
    case ACTIONS.PREPARE_PAY_IN_FULL_POPUP: {
      return {
        ...state,
        isMobileRounded: true,
      };
    }
    case ACTIONS.SET_TITLES: {
      return {
        ...state,
        titles: action.payload,
      };
    }
    case ACTIONS.UPDATE_FROM_DATASET: {
      const {
        tabbyLanguage,
        tabbyLocale,
        tabbyInstallmentsCount,
        tabbyPrice,
        tabbyCurrency,
      } = action.payload;

      const langAndLocale = getLangAndLocale(
        tabbyLanguage as Lang,
        tabbyLocale as Locale
      );

      return {
        ...state,
        installmentsCount: checkInstallmentsCount(INSTALLMENTS_COUNT_DEFAULT)(
          tabbyInstallmentsCount
        ),
        price: checkPrice(tabbyPrice),
        priceRaw: tabbyPrice,
        currency: checkCurrency(tabbyCurrency),
        formattedCurrency: tabbyCurrency as AllowedCurrency,
        ...(langAndLocale
          ? {
              lang: checkLang(langAndLocale.lang, {
                locale: langAndLocale.locale,
              }),
              locale: checkLocale(langAndLocale.locale, {
                lang: langAndLocale.lang,
              }),
            }
          : {}),
      };
    }
    case ACTIONS.PREPARE_ALSHAYA_LIMIT: {
      return {
        ...state,
        isBig: true,
      };
    }
    case ACTIONS.PREPARE_SCHEDULE_POPUP: {
      return {
        ...state,
        isBig: true,
        isDark: true,
        isBold: true,
        isMobileRounded: true,
        isShortSplitIt: true,
      };
    }
    case ACTIONS.UPDATE_STORE: {
      return {
        ...state,
        ...action.payload,
        ...('installmentsCount' in action.payload
          ? {
              installmentsCount: checkInstallmentsCount(
                INSTALLMENTS_COUNT_DEFAULT
              )(action.payload.installmentsCount),
            }
          : {}),
        ...('lang' in action.payload
          ? {
              lang: checkLang(action.payload.lang, { locale: state.locale }),
            }
          : {}),
        ...('locale' in action.payload
          ? { locale: checkLocale(action.payload.locale, { lang: state.lang }) }
          : {}),
        ...('price' in action.payload
          ? {
              price: checkPrice(action.payload.price),
              ...(action.payload.skipRawPriceUpdate
                ? {}
                : { priceRaw: action.payload.price }),
            }
          : {}),
        ...('currency' in action.payload
          ? {
              currency: checkCurrency(action.payload.currency),
              formattedCurrency: formatCurrency(action.payload.currency),
            }
          : {}),
      };
    }

    default:
      return state;
  }
};
