import { CurrencyType } from 'src/types/currency';
import { getFractionDigitsCount } from 'src/utils/get-fraction-digits-count';

function calculateFractionalPartLength(
  hideFractionalPart: boolean,
  defaultFractionalPartLength: number,
  value: number
) {
  if (!hideFractionalPart) {
    return defaultFractionalPartLength;
  }

  return value % 1 === 0 ? 0 : defaultFractionalPartLength;
}

export function getPriceSplitOf(
  price: number,
  currency: CurrencyType,
  splitCount = 4,
  hideFractionalPart = false
) {
  const value = parseFloat(`${price / splitCount}`);

  const defaultFractionalPartLength = getFractionDigitsCount(currency);

  const fractionalPartLength = calculateFractionalPartLength(
    hideFractionalPart,
    defaultFractionalPartLength,
    value
  );

  return parseFloat(`${price / splitCount}`).toFixed(fractionalPartLength);
}
