const BASE_URL = `${process.env.CHECKOUT_HOST}/widgets/api`;
const ERROR_CODES = [409, 429, 500, 502, 503, 504];
const RETRIES = 3;
const BACKOFF = 300;

interface Params {
  url: string;
  options: Record<string, any>;
  retries?: number;
  backoff?: number;
}

export const fetchWithRetry = async ({
  url,
  options,
  retries = RETRIES,
  backoff = BACKOFF,
}: Params) => {
  try {
    const response = await fetch(`${BASE_URL}${url}`, options);

    if (!response.ok) {
      if (retries > 0 && ERROR_CODES.includes(response.status)) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(null);
          }, backoff);
        });

        return fetchWithRetry({
          url,
          options,
          retries: retries - 1,
          backoff: backoff * 2,
        });
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Request failed: ${url}`, error);
    throw error;
  }
};
