import { datadogRum } from '@datadog/browser-rum';
import cn from 'classnames';
import React from 'react';
import NumberFormat from 'react-number-format';
import { Line } from 'src/components/card-widget/components/line';
import { Subtitle } from 'src/components/card-widget/components/subtitle';
import { Currency } from 'src/components/currency';
import { ShadowView } from 'src/components/shadow-view';
import { WIDGET_INITIALIZED } from 'src/constants/common';
import {
  AppProvider,
  MerchantProps,
  useAppContext,
} from 'src/context/store/app-provider';
import { useLocale } from 'src/hooks/use-locale';
import { useOnExperimentsReady } from 'src/hooks/use-on-experiments-ready';
import { CardTheme } from 'src/types/card-theme';
import { initializeDatadogRUMWidgets } from 'src/utils/datadog';
import { delayDecorator } from 'src/utils/delay-decorator';
import { getPriceSplitOf } from 'src/utils/get-price-split-of';
import { renderToDecorator } from 'src/utils/render-to-decorator';

import styles from './styles.css';

export function CardWidget() {
  const {
    isRtl,
    currency,
    price,
    shouldInheritBg,
    size = 'narrow',
  } = useAppContext();
  const pricePart = getPriceSplitOf(price, currency);
  const t = useLocale();

  useOnExperimentsReady(initializeDatadogRUMWidgets);

  const description = (
    <NumberFormat
      value={pricePart}
      renderText={(formattedValue) => (
        <>
          <p>{formattedValue}</p>
          <p
            className={cn(
              size === 'narrow' &&
                (isRtl ? styles.narrowGapRtl : styles.narrowGap),
              size === 'wide' && styles.wideGap
            )}
          >
            <Currency currency={currency} />
          </p>
        </>
      )}
      displayType="text"
      allowNegative={false}
    />
  );

  if (pricePart === null) return null;

  return (
    <ShadowView shouldInheritColor shouldInheritFontFamily>
      <div
        className={cn(
          styles.tabbyCard,
          isRtl ? styles.tabbyCardRtl : null,
          size === 'wide' ? styles.tabbyCardWide : null,
          shouldInheritBg ? null : styles.tabbyCardBgWhite
        )}
      >
        <Subtitle>{t('tabbyCard.use_any_card')}</Subtitle>
        <Line
          list={[
            {
              title: t('tabbyCard.Today'),
              description,
            },
            {
              title: t('tabbyCard.In 1 month'),
              description,
            },
            {
              title: t('tabbyCard.In 2 months'),
              description,
            },
            {
              title: t('tabbyCard.In 3 months'),
              description,
            },
          ]}
        />
      </div>
    </ShadowView>
  );
}

declare global {
  interface Window {
    TabbyCard: (props: { selector: string } & MerchantProps<CardTheme>) => void;
  }
}

window.TabbyCard = ({ selector, ...props }) => {
  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'TabbyCard',
    ...props,
  });

  delayDecorator(() => {
    renderToDecorator(
      selector,
      <AppProvider {...props}>
        <CardWidget />
      </AppProvider>
    );
  });
};
