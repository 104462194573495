import React, { ReactElement } from 'react';

export const replaceStringTagToComponent = (
  string: string,
  wrapperMap: Record<string, (s?: string) => ReactElement>
): any => {
  // Returning 'any' because it's hard to determine type due to various possible values.
  return string
    .split(/(<[a-z]+>[^<]+<\/[a-z]+>|<[a-z]+\s?\/>)/)
    .map((splittedTextValue, i) => {
      const [, tagWithContent, innerText, selfClosingTag] =
        splittedTextValue.match(
          /<([a-z]+)>([^<]+)<\/[a-z]+>|<([a-z]+)\s?\/>/
        ) || [];

      const renderComponent = (content) => (
        <React.Fragment key={i}>{content}</React.Fragment>
      );

      if (tagWithContent && wrapperMap[tagWithContent])
        return renderComponent(wrapperMap[tagWithContent](innerText));

      if (selfClosingTag && wrapperMap[selfClosingTag])
        return renderComponent(wrapperMap[selfClosingTag]());

      return renderComponent(splittedTextValue);
    });
};
