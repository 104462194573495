export const CurrencyType = {
  AED: 'AED',
  KWD: 'KWD',
  SAR: 'SAR',
  BHD: 'BHD',
  QAR: 'QAR',
} as const;

export type CurrencyType = typeof CurrencyType[keyof typeof CurrencyType];

export const allowedCurrencies = [
  CurrencyType.AED,
  CurrencyType.SAR,
  CurrencyType.KWD,
] as const;

export type AllowedCurrency = typeof allowedCurrencies[number];
