export function convertToSnakeCase(obj: object): object {
  if (obj === null || typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map(convertToSnakeCase);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const snakeKey = key
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    acc[snakeKey] = convertToSnakeCase(obj[key]);
    return acc;
  }, {});
}
