import React from 'react';
import { Icon } from 'src/components/icon';
import { useLocale } from 'src/hooks/use-locale';
import { CurrencyType } from 'src/types/currency';

import styles from './styles.css';

export function Currency({ currency }: { currency: CurrencyType }) {
  const t = useLocale();

  const currencyTranslate: Record<CurrencyType, string> = {
    [CurrencyType.AED]: t('AED'),
    [CurrencyType.KWD]: t('KWD'),
    [CurrencyType.SAR]: t('SAR'),
    [CurrencyType.BHD]: t('BHD'),
    [CurrencyType.QAR]: t('QAR'),
  };

  if (currency === CurrencyType.SAR) {
    return <Icon icon="riyal" svgAttributes={{ className: styles.icon }} />;
  }

  return <>{currencyTranslate[currency]}</>;
}
