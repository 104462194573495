import { CurrencyType } from 'src/types/currency';
import { getTranslationFn } from 'src/utils/get-translation-fn';

export const getFractionDigitsCount = (currency?: CurrencyType) => {
  if (
    Array<CurrencyType>(CurrencyType.KWD, CurrencyType.BHD).includes(
      currency as CurrencyType
    )
  ) {
    return 3;
  }

  let t = getTranslationFn('ar', 'ar-KW');

  if (currency === t('KWD')) {
    return 3;
  }

  t = getTranslationFn('ar', 'ar-BH');

  if (currency === t('BHD')) {
    return 3;
  }

  return 2;
};
