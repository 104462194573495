import React, { ReactElement, SVGAttributes } from 'react';

import { ReactComponent as BagIcon } from './assets/bag.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';
import { ReactComponent as PeopleIcon } from './assets/people.svg';
import { ReactComponent as PercentagesIcon } from './assets/percentages.svg';
import { ReactComponent as RamdanIcon } from './assets/ramadan.svg';
import { ReactComponent as Riyal } from './assets/riyal.svg';
import { ReactComponent as ShariatIcon } from './assets/shariat.svg';
import { ReactComponent as ShariatModernIcon } from './assets/shariat-modern.svg';
import { ReactComponent as TabbyCareIcon } from './assets/tabby-care.svg';

export interface IconProps {
  icon:
    | 'info'
    | 'bag'
    | 'tabby-care'
    | 'shariat'
    | 'shariat-modern'
    | 'percentages'
    | 'people'
    | 'ramadan'
    | 'riyal';
  svgAttributes?: SVGAttributes<SVGSVGElement>;
}

const ICONS: Record<
  IconProps['icon'],
  (props?: SVGAttributes<SVGSVGElement>) => ReactElement
> = {
  info: (props) => <InfoIcon {...props} />,
  bag: (props) => <BagIcon {...props} />,
  'tabby-care': (props) => <TabbyCareIcon {...props} />,
  shariat: (props) => <ShariatIcon {...props} />,
  'shariat-modern': (props) => <ShariatModernIcon {...props} />,
  percentages: (props) => <PercentagesIcon {...props} />,
  people: (props) => <PeopleIcon {...props} />,
  ramadan: (props) => <RamdanIcon {...props} />,
  riyal: (props) => <Riyal {...props} />,
};

export function Icon({ icon, svgAttributes }: IconProps) {
  return ICONS[icon](svgAttributes);
}
